<template>
  <!-- 为收货管理页面 - 批次信息展示使用, model 返回的 uuid 相同, 渲染了相同的组件，因此要使用 key来做区分  key=delivervidlist -->
  <!-- 该组件只显示数据 -->
  <div class="item-content" v-show="visible">
    <el-form ref="childForm" @submit.native.prevent :rules="rules" :model="formLabelAlign">

      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference && fatherContent.displayReference.description"
        >
          <div slot="content"> {{ fatherContent.displayReference.description }} </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>

      <el-form-item prop="value">
        <div class="options-info">
          <div>{{ $t("common.product_name") }}: {{ batchInfo && batchInfo.product && batchInfo.product.name || '-' }}</div>
          <div>{{ $t("common.batch_info") }}: {{ batchInfo && batchInfo.product_bind && batchInfo.product_bind.vid_info.custom_id || '-' }}</div>
          <div class="product-info">
            <el-image style="border: 1px solid #eee; border-radius: 4px;" :src="imageUrl" :fit="'contain'" />
            <span>{{ $t("common.product_count") }}: {{ batchInfo && batchInfo.product_bind && batchInfo.product_bind.amount || 0 }}</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import language from '@/utils/lang';
import { mapGetters } from 'vuex';
import * as R from 'ramda';

export default {
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host","listHost", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      formLabelAlign: { value: "" },
      batchInfo: {},
      imageUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent) > 0
        ? this.jsonContent
        : this.fatherContent;
    },
    dcpDataInfo() {
      return this.$store.state.dcpDataInfo;
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        if (!val.value) return
        const value = `${val.value.vid},${val.value.custom_id}`; // 提交的数据需要逗号拼接组合
        const data = {
          vidlist: [value],
          type: 1,
          num: 1
        };
        this.$emit("confirm", data);  // 往vid父组件推数据
      },
      deep: true
    },
    "$store.state.receiveInfo": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getBatchInfo()
        }
      }
    }
  },
  methods: {
    spliceUrl(location) {
      return window.Config.base_url + window.Config.image_path + `/${location}`;
    },
    async getBatchInfo() {
      const info = this.$store.state.receiveInfo;
      this.batchInfo = info;
      const image = R.pathOr('', ['product', 'image'])(info);
      this.imageUrl = this.spliceUrl(image);

      const vid_info = R.pathOr({}, ['product_bind', 'vid_info'])(info);
      this.formLabelAlign.value = vid_info; // 查询到的数据回填到要提交的大表单对象中
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  created() {},
  mounted() {
    if (this.datamodel !== undefined) {
      if (Object.keys(this.datamodel).length === 0) {
        this.formLabelAlign.value = ''
      } else {
        this.formLabelAlign.value = this.datamodel || '';
      }
    } else {
      this.formLabelAlign.value = this.jsonContent.defaultValue || this.fatherContent.displayReference.defaultValue || ''
    }
    this.getBatchInfo();
  }
};
</script>
<style lang='scss' scoped>
.item-content {
  padding-top: 10px;
  text-align: left;
  margin-bottom: 10px;
}

.options-info {
  padding: 10px;
  line-height: 25px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-image {
  height: 50px;
  width: 50px;
  & > img {
    height: 50px;
    width: 50px;
  }
}

</style>
