<template>
  <!-- 文本输入框拆分出来的组件 因为要共用一个 uuid 来渲染不同组件-->
  <div class="item-content" v-show="visible">
    <el-form
      ref="childForm"
      @submit.native.prevent
      :rules="rules"
      :model="formLabelAlign"
    >
      <div class="item-tooltip-title">
        <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
        <el-tooltip
          class="item"
          effect="light"
          placement="bottom"
          v-if="fatherContent.displayReference.description"
        >
          <div slot="content">
            {{ fatherContent.displayReference.description }}
          </div>
          <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
        </el-tooltip>
      </div>
      <el-form-item prop="value">
        <el-button
          type="text"
          class="dcp-collector-add-button"
          v-if="showDataRefButton"
          :disabled="cannotEdit"
          @click="addDcuRef()"
          icon="el-icon-circle-plus-outline"
          >{{ $t('app.add_ref_data_button') }}</el-button
        >
        <div class="input-setting-icon" @click="settingScanSource" v-if="showScannerIcon">
          <img :src="require('@/assets/icons/dcu/icon-text-setting.png')" width="24px" height="24px"/>
        </div>

        <el-input
          v-model.trim="itemdata"
          @focus="showInputView"
          :placeholder="localized(`prompt`) || ''"
          :disabled="cannotEdit || dataRefInput || receiveDelivernameDisabled || isReceiveAndReceived"
          class="rt-input"
          show-word-limit
          :maxlength="fatherContent.controlReference.max"
        >
          <template slot="prefix" v-if="showDataRefButton">
            <el-tag
              style="padding-right: 20px; padding-left: 20px"
              v-if="showDataRefButton && dataRefValue && dataRefValue.length"
              :key="dataRefValue"
              @close="deleteDataRef"
              :closable="!cannotEdit"
            >
              {{ dataRefValue }}
            </el-tag>
          </template>

          <template slot="suffix" v-if="showScannerIcon">
            <div style="width:50px; height:100%; display:flex; justify-content:center; align-items: center" @click="scanText">
              <img src="@/assets/icons/dcu/scan.png" style="width: 16px; height: 16px; margin-left: 10px"/>
            </div>
          </template>
        </el-input>
      </el-form-item>
    </el-form>

    <el-dialog
      :visible.sync="showInputArea"
      top="15vh"
      class="input-dialog"
      width="90%"
    >
      <el-input
        type="textarea"
        v-model.trim="tempData"
        :placeholder="localized(`prompt`) || ''"
        :autosize="{ minRows: 4 }"
        ref="inputRef"
        autofocus
        style="margin-top: -20px;"
      ></el-input>
      <el-row
        type="flex"
        class="row-bg"
        justify="space-between"
        style="margin-top: 20px;"
      >
        <el-col :span="11">
          <el-button
            @click="rollbackData"
            size="small"
            round
            class="btn-white-color"
            >{{ $t('app.btn_clear') }}</el-button
          >
        </el-col>
        <el-col :span="11">
          <el-button
            @click="confirmData"
            size="small"
            round
            class="btn-white-color"
            >{{ $t('app.btn_confirm') }}</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      :title="$t('app.scan_dialog_title')"
      :visible.sync="scannerResultVisible"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="scan-dialog"
      top="0"
      width="90%"
    >

    <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="font-weight: 500;">{{$t('app.scan_result_total')}}: {{scanResultText.length}}</span>&nbsp;
        </div>
        <div class="scan-result" v-for="data in scanResultText" :key="data">
          <div class="left">{{data}}</div>
          <div class="right">
            <span class="status">
              <i
                class="el-icon-delete"
                style="font-size: 20px;"
                :style="'lawngreen'"
                @click="deleteText(data)"
              ></i>
            </span>
            <span class="clearVid"></span>
          </div>
        </div>
        <div v-if="!scanResultText.length" class="no-data">{{$t('app.no_data')}}</div>
      </el-card>
      <div
        class="scan_tip"
        v-if="resultType !== 'view'"
      >{{selectScanSourceValue && $t('app.' + selectScanSourceValue.split('.')[0] + '_msg')}}</div>
      <span slot="footer" class="dialog-footer">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="7" v-if="isIos ||  scanSelect.indexOf('qr') > -1">
            <el-button
              @click="addScanText"
              size="small"
              round
              :disabled="cannotEdit"
              class="btn-white-color"
            >{{$t('app.btn_add')}}</el-button>
          </el-col>
          <el-col :span="isIos ||  scanSelect.indexOf('qr') > -1 ? 7 : 11">
            <el-button
              @click="clearScanResult"
              size="small"
              round
              class="btn-white-color"
            >{{$t('app.btn_clear')}}</el-button>
          </el-col>
          <el-col :span="isIos ||  scanSelect.indexOf('qr') > -1 ? 7 : 11">

            <el-button
              class="btn-black-color"
              round
              :disabled="cannotEdit"
              @click="confirmScanResult"
              size="small"
            >{{$t('app.btn_confirm')}}</el-button>
          </el-col>
        </el-row>
      </span>

    </el-dialog>

    <el-dialog
      :title="$t('app.choose_dialog_title')"
      top="20vh"
      width="90%"
      :visible.sync="dataSourceChooseVisable"
    >
      <div
        v-for="(scan, index) in scanSources"
        @click="selectScan(scan)"
        :class="{ 'active-selected-scan': selectScanSourceType === scan.value }"
        class="scan-item"
        :key="index"
      >
        {{$t(`app.${scan.text}`)}}
        <span :class="{ 'el-icon-check': selectScanSourceType === scan.value }"></span>
      </div>
      <el-radio-group
        v-model="selectScanSourceValue"
        size="small"
        v-if="selectScanSourceType && scanColl[selectScanSourceType] && scanColl[selectScanSourceType].length > 1"
        class="text-source-radio"
        style="flex-wrap: wrap; width: 100%; height: auto; display: flex;"
      >

        <template v-for="(type) in scanColl[selectScanSourceType]">
          <div style="width:50%; display:flex; justify-content: flex-start;">
            <el-radio
              style="line-height: 30px;"
              :key="type"
              :label="type"
            >{{$t(`app.${type}`)}}</el-radio>
          </div>
        </template>

      </el-radio-group>

      <el-radio-group size="small" v-if="!selectScanSourceType">
        <el-radio style="line-height: 24px;" label="qrcode_single" disabled>{{$t('app.qrcode_single')}}</el-radio>
        <el-radio style="line-height: 24px;" label="qrcode_multi" disabled>{{$t('app.qrcode_multi')}}</el-radio>
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-row type="flex" class="row-bg" justify="space-between" style="margin-top: 20px;">
          <el-col :span="11">
            <el-button
              @click="dataSourceChooseVisable = false"
              size="small"
              round
              class="btn-white-color"
            >{{$t('app.btn_clear')}}</el-button>
          </el-col>
          <el-col :span="11">
            <el-button
              class="btn-black-color"
              round
              :disabled="!selectScanSourceType || !selectScanSourceValue"
              @click="confirmSelect"
              size="small"
            >{{$t('app.btn_save')}}</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import language from '@/utils/lang'
import { mapGetters } from 'vuex'
import callApp from "@/services/sdk";
import {shipGroupNameDcuKeys, shipOrgNameDcuKeys} from "@/utils/config";
import { commonStoreModule } from "@/store/module/common";
import * as R from 'ramda';

export default {
  inject: {
    commonSave: {
      default: {}
    }
  },
  components: {
  },
  props: [
    'jsonContent',
    'fatherContent',
    'datamodel',
    'disabled',
    'host',
    "listHost",
    'contextData',
    'editStatus'
  ],
  data() {
    return {
      showInputArea: false,
      itemdata: '',
      formLabelAlign: {
        value: ''
      },
      tempData: '',
      label: '',
      dataRefValue: '',
      showDataRef: false,
      deleteRefData: '',

      scanColl: {},
      dataSourceChooseVisable: false,
      scannerResultVisible: false,
      currentScannerDataSource: '',

      scanSources: [],
      selectScanSourceType: "",
      selectScanSourceValue: "qrcode_single",
      scanSelect: "",

      scanResultText: [],
      resultType: "",
      isIos: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),

      productBatchInfo: {}, // 商品批次码信息(收货管理页面使用)
    }
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList',
      getChildPageContext: 'getChildPageContext'
    }),
    dataRefInput() {
      let dataSource = this.fatherContent.controlReference.dataSource || []
      if (dataSource.length === 0) {
        return false
      }

      if (
        dataSource.indexOf('Remote.Reference.In') < 0 &&
        dataSource.indexOf('Remote.Reference.Out') < 0
      ) {
        return false
      }
      if (
        this.showDataRefButton &&
        this.dataRefValue &&
        this.dataRefValue.length
      ) {
        return true
      }
      return false
    },
    cannotEdit(){
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    showDataRefButton() {
      let dataSource = this.fatherContent.controlReference.dataSource || []
      if (dataSource.length === 0) {
        return false
      }

      if (
        dataSource.indexOf('Remote.Reference.In') < 0 &&
        dataSource.indexOf('Remote.Reference.Out') < 0
      ) {
        return false
      }
      // return true
      let commonSaveElement = this.contextData && this.contextData['refDcpConfigList'] || []
      let filterDcpConfigList = commonSaveElement.filter(item => {
        let dcuPathArray = item.dcuPath.split('.')
        return dcuPathArray[dcuPathArray.length - 1] === this.fatherContent.key
      })
      return filterDcpConfigList.length > 0
    },
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error'),
            trigger: 'blur'
          },
          {
            min: this.fatherContent.controlReference.min || 1,
            max: this.fatherContent.controlReference.max,
            message: `From ${this.fatherContent.controlReference.min} to ${
              this.fatherContent.controlReference.max
            } character`,
            trigger: 'blur'
          }
        ]
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible && !this.invisibleKeyPath
      }
      return this.fatherContent.displayReference.visible && !this.invisibleKeyPath
    },
    invisibleKeyPath() {
      return this.commonSave.invisibleKeyPathList && this.commonSave.invisibleKeyPathList.includes(this.hostKey)
    },
    hostKey() {
      return this.host + '.' + this.fatherContent.key
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    unitKey() {
      return this.fatherContent.key;
    },
    receiveDelivernameDisabled() {
      // 收货管理中的发货方字段是前端控制为不可编辑状态，只显示内容
      return this.fatherContent.key === 'delivername'
    },
    isReceiveAndReceived() {
      // 收货管理 - 备注字段 - 收货状态为已收货时无法进行内容修改
      const dcp_type = this.$route.query?.dcp_type;
      const state = this.$store.state?.receiveInfo?.receive_state;
      return dcp_type === 'receive' && state === 'SUCCESS';
    },
    refDcuContent() {
      var refContent =
        (this.getChildPageContext[this.hostKey] &&
          this.getChildPageContext[this.hostKey].refContent &&
          this.getChildPageContext[this.hostKey].refContent.value) ||
        this.datamodel
      return refContent
    },
    showScannerIcon() {
      return this.fatherContent.controlReference && this.fatherContent.controlReference.dataSource.includes('Local.Scanner')
    },
    dcpDataInfo() {
      return this.$store.state.dcpDataInfo;
    },
    receiveInfo() {
      return this.$store.state.receiveInfo;
    },
    deliverInfo() {
      return this.$store.state.deliverDcpData;
    },
  },
  methods: {
    // 收货管理中的发货方需要获取批次信息
    async getBatchInfo() {
      const query = this.$route.query;
      const batchCode = query?.batch_code;
      if (batchCode === null) { // app 新建收货管理
        this.itemdata = null;
      } else { // batch_code 有值直接从 store receiveInfo 对象中取
        const delivername = R.pathOr('', ['deliver_department', 'name'])(this.receiveInfo); // 发货方姓名: 创建者是谁，发货方就是谁
        this.itemdata = delivername;
      }
    },
    showInputView() {
      // this.showInputArea = true
      this.showInputArea = false; // 新需求，单行文本不弹窗输入，并显示输入长度控制
      setTimeout(() => {
        // this.$refs["inputRef"].focus()
        this.tempData = this.itemdata
      }, 100)
    },
    setShipValue() {
        let roleInfo = commonStoreModule.getRoleInfo;
        let groupName = roleInfo.groupName;
        let suborgName = roleInfo.suborgName;
        if (shipGroupNameDcuKeys.includes(this.hostKey)) {

          this.formLabelAlign.value = groupName

        } else if(shipOrgNameDcuKeys.includes(this.hostKey)) {

          this.formLabelAlign.value = suborgName
        }
    },
    deleteText(text){
      if (this.disabled) return;
      let totalIndex;
      this.scanResultText.forEach((item, index) => {
        if (item === text) {
          totalIndex = index;
        }
      });
      if (totalIndex !== undefined) {
        this.scanResultText.splice(totalIndex, 1);
      }
    },
    addScanText(){
      this.callAppScanText()
    },
    confirmSelect(source){
        this.scanSelect = this.selectScanSourceType + "." + this.selectScanSourceValue;
        this.currentScannerDataSource = this.scanSelect
        this.$nextTick(() => {
          callApp(
            "updateSelectedTextSource",
            {
              type: "storage.updateSelectedTextSource",
              data: {
                key: this.hostKey,
                val: this.scanSelect
              }
            },
            "cbUpdateSelectedTextSource"
          ).then(data => {
            this.dataSourceChooseVisable = false;
          });
        });
    },
    settingScanSource() {
        this.scanColl = {};
        callApp(
          "getTextSource",
          {
            type: "device.textSource",
            data: { }
          },
          "cbGetTextSource"
        ).then(data => {
          this.scanSources = data.textSources
            .map(item => {
              let scan = item.split(".");
              if (this.scanColl[scan[0]]) {
                this.scanColl[scan[0]].push(scan[1]);
              } else {
                this.scanColl[scan[0]] = [scan[1]];
                return { text: scan[0], value: scan[0] };
              }
            })
            .filter(item => item !== undefined);
          this.$nextTick(_ => {
            this.dataSourceChooseVisable = true
          });
        });
    },
    createRandom() {
      return (
        +new Date() +
        Math.random()
          .toString()
          .substr(2, 4)
      );
    },
    scanText(){

        if(!this.currentScannerDataSource){
          this.settingScanSource()
          return
        }
        if(this.disabled){
          return
        }
        this.scannerResultVisible = true
        this.callAppScanText()
    },
    callAppScanText(){
      const requestId = this.createRandom();

      callApp(
        "getData",
        {
          type: `dataSource.${this.scanSelect}`,
          data: {
            max: this.fatherContent.controlReference.max - this.scanResultText.length
          }
        },
        "cbGetData",
        "",
        requestId
      ).then(data => {
        if (!this.isIos) {
          // 安卓因为多扫，所以扫描的逻辑不同
          window["web.setTextData" + requestId] = data => {
            if (this.fatherContent.controlReference.max - (this.scanResultText.length + data.length) < 0) {
              this.$toast({
                message: this.$t("app.scan_content_overflow"),
                position: "center",
                iconClass: "icon el-icon-warning",
                duration: "3000"
              });
              return;
            }
            if (this.fatherContent.controlReference.max > 1) {

              data.vids.forEach(item => {

                if(this.scanResultText.indexOf(item.text) > -1){
                  return
                }else{
                  this.scanResultText.push(item.text)
                }
              });

            } else {
              this.scanResultText = data.vids;
            }
          };
        } else {
          // ios逻辑
          if (this.fatherContent.controlReference.max > 1) {
            data.vids.forEach(item => {

              if(this.scanResultText.indexOf(item.text) > -1){
                return
              }else{
                this.scanResultText.push(item.text)
              }
            });
          } else {
            this.scanResultText = data.vids;
          }
        }
      });
    },
    clearScanResult() {

      this.scanResultText = []
      this.scannerResultVisible = false;

      callApp(
        "getData",
        {
          type: `device.closeScan`,
          data: this.selectScanSourceType
        },
        "cbGetData"
      );
    },
    confirmScanResult() {
      let text = ""
      this.scanResultText.forEach(item=>{
        if(text.length > 0){
          text = text + '\n' + item
        }else{
          text = item
        }
      })

      this.itemdata = text
      this.scanResultText = [];

      this.scannerResultVisible = false;

      callApp(
        "getData",
        {
          type: `device.closeScan`,
          data: this.selectScanSourceType
        },
        "cbGetData"
      );
    },
    selectScan(scan){
        this.selectScanSourceType = scan.value;
        this.selectScanSourceValue = "qrcode_single";
    },
    confirmData() {
      this.showInputArea = false
      this.itemdata = this.tempData
      this.formLabelAlign.value = this.itemdata
      this.$refs.childForm.validate()
    },
    rollbackData() {
      this.showInputArea = false
      this.tempData = this.itemdata
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    },
    addDcuRef() {
      let key = this.fatherContent.key
      let uuid = this.$store.state.uuid
      let projectId = this.$store.state.projectId
      let refContent =
        (this.getChildPageContext[this.hostKey] &&
          this.getChildPageContext[this.hostKey].refContent) ||
        ''
      let context = {
        father: { key, uuid, projectId },
        hostKey: this.hostKey,
        refContent
      }
      let childPageContext = this.getChildPageContext
      childPageContext[this.hostKey] = context
      this.$store.dispatch('setChildPageContext', childPageContext)
      this.$emit('cacheDatamodel')
      this.$router.push({
        path: '/v2/dcplist',
        query: { hostKey: this.hostKey, type: 'dcu' }
      })
    },
    deleteDataRef() {
      this.getChildPageContext[this.hostKey] = {}
      let refData = {
        storagekey: this.hostKey
      }
      this.deleteRefData = refData
      let childPageContext = this.getChildPageContext
      childPageContext[this.hostKey] = {}
      this.$store.dispatch('setChildPageContext', childPageContext)
      this.dataRefValue = ''
      this.datamodel = ''
      this.itemdata = ''

      this.$emit('updateRefDcu', { refData, action: 'delete' })
    }
  },
  created() {},
  mounted() {
    if (this.datamodel !== undefined) {
      if (Object.keys(this.datamodel).length === 0) {
        this.formLabelAlign.value = ''
      } else {
        let isCopy = this.$route.query.copyRecord;
        if (isCopy && this.fatherContent.key === 'skucode') {
          this.formLabelAlign.value = undefined
        }else{
          this.formLabelAlign.value = this.datamodel || '';
        }
      }
    } else {
      this.formLabelAlign.value =
        this.jsonContent.defaultValue ||
        this.fatherContent.displayReference.defaultValue ||
        undefined
    }
    this.itemdata = this.formLabelAlign.value

    // 收货管理页面特殊处理：需要获取商品批次信息，从信息中拿发货方名字进行渲染
    if (this.receiveDelivernameDisabled && !this.itemdata) {
      this.getBatchInfo();
    }
    this.label =
      this.fatherContent &&
      this.fatherContent.displayReference &&
      this.fatherContent.displayReference.label

    if (this.datamodel !== undefined) {
      let dataRefList = (this.contextData && this.contextData['dcpreferences'])
        ? this.contextData['dcpreferences'].filter(item => {
            return this.hostKey === item.storagekey
          })
        : []
      if (dataRefList && dataRefList.length !== 0) {
        this.dataRefValue = this.datamodel || ''
        this.formLabelAlign.value = this.datamodel || ''
      } else {
        this.formLabelAlign.value = this.datamodel || ''
      }
    }

    this.dataRefValue = this.refDcuContent

    let refData =
      this.getChildPageContext[this.hostKey] &&
      this.getChildPageContext[this.hostKey].refContent &&
      this.getChildPageContext[this.hostKey].refContent.refData
    if (refData) {
      this.itemdata = this.dataRefValue
      this.$emit('updateRefDcu', { refData, action: 'update' })
    }

    if (this.fatherContent.controlReference && this.fatherContent.controlReference.dataSource.includes('Local.Scanner')) {
          this.$store.state.scannerTextComponents.push(this); // 缓存vid组件实例
          window["web.closeScanDialog"] = () => {
            this.$store.state.scannerTextComponents.forEach(instance => {
              // 切换扫描类别时, 关闭扫描的弹窗
              instance.scannerResultVisible = false;
            });
          };
          callApp(
            "getSelectedTextSource",
            {
              type: "storage.getSelectedTextSource",
              data: {
                key: this.hostKey
              }
            },
            "cbGetSelectedVidSource"
          ).then(data => {
            this.selectScanSourceType = data.split(".")[0];
            this.selectScanSourceValue = data.split(".")[1];
            this.scanSelect = data;
            this.currentScannerDataSource = data
          });
      }
      setTimeout(()=> {
        this.setShipValue()
      }, 100)
  },
  watch: {
    itemdata: {
      immediate: true,
      handler(val) {
        this.formLabelAlign.value = val
        var va = val === '' || val === undefined ? undefined : val
        this.$emit('fatherCall', {
          key: this.fatherContent.key,
          value: va
        })
      }
    },
    formLabelAlign: {
      handler(val) {
        this.$emit("confirm", val.value);
      },
      deep: true
    },
    datamodel: { // 监听 datamodel 变化，因为组件是全部更新，如果数据后更新，将无法看到最新数据
      immediate: true,
      handler: function (val) {
        if (val) {
          const dcp_type = this.$route.query?.dcp_type;
          const reactiveState = R.pathOr('', ['receive_state'])(this.receiveInfo);

          if (dcp_type === 'receive' && reactiveState === 'WAIT_RECEIVE' && this.unitKey === 'department') { // 收货管理 待收货状态时 备注信息 department 应该为空
            this.itemdata = '';
          } else {
            this.itemdata = val;
          }
        }
      }
    },
    "$store.state.receiveInfo": { // enter 中 batchCodeWatch 变化触发 receiveInfo 的变化，因此监听 receiveInfo，这里是为了给 key=delivername 赋值， app 使用
      immediate: true,
      handler: function (val) {
        if (val) {
          const delivername = R.pathOr('', ['deliver_department', 'name'])(this.receiveInfo); // 发货方姓名: 创建者是谁，发货方就是谁
          if (this.unitKey === 'delivername') { // 加上 key 判断
            this.itemdata = delivername;
          }
        }
      }
    },
    "$store.state.deliverDcpData": { // 只能通过 watch 方式监控 store 中的值
      immediate: true,
      handler: function (val) {
        if (val) {
          const dcp_type = this.$route.query?.dcp_type;
          if (this.unitKey === 'textplain_003' && dcp_type === 'receive') { // 发货备注(textplain_003)的值就是 发货数据 department 字段的值
            const department = R.pathOr('', ['custom', 'department'])(this.deliverInfo);
            this.itemdata = department;
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.item-content {
  text-align: left;
  margin: 10px auto;
}
.item-tooltip-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    line-height: 19px;
    margin-right: 10px;
  }
}
.rt-input {
  &.el-input.is-disabled .el-input__inner {
    background-color: #f5f7fa !important;
    border-color: transparent !important;
    color: black !important;
    cursor: not-allowed !important;
  }

  input:disabled {
    opacity: 1 !important;
  }
}
.dcp-collector-add-button {
  font-size: 13px;
  position: absolute;
  padding: 4px 4px 0 4px;
  right: 0;
  top: 0;
  z-index: 100;
}
.input-setting-icon {
  font-size: 13px;
  position: absolute;
  padding: 4px 4px 0 4px;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-source-radio {
    display: block;
    margin-top: 30px;
    text-align: center;

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #000;
      background: #000;
    }

    .el-radio__input.is-checked + .el-radio__label {
      color: #000;
    }
  }


</style>
