






import { Component, Prop, Vue } from 'vue-property-decorator'
import callApp from "@/services/sdk"
import { BizType, Scene } from "@/utils/config";
import { createRequestId, shortVid } from '@/utils/vid'

@Component({
  components: {},
  filters:{}
})
export default class VidScanner extends Vue {

  // props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  private scannedVidList: Array<any> = []

  private vidContent: string = ''

  private get vidErrorStyle(): string {
    return "border-color: #dcdfe6"
  }

  // computed
  private get infos(): Array<any> {
    return Array<any>()
  }

  private get showContent(): string {
    if(this.scannedVidList.length <= 0){
        return ""
    }
    return shortVid(this.scannedVidList[0].vid)
  }

  // lifecycle
  created() {

  }

  mounted() {

  }

  // methods
  public methodOne(params: any): void {

  }

  private get isIos(): boolean {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }

  // 扫芯片时根据错误code判断是否继续，非1就提示后端给的错误code对应的内容
  public getErrorCodeToNext = (data: any) => {
    const errorCode = data?.vids[0].code;
    if (errorCode !== 1) {
      const msg = this.$t("app.viderror." + errorCode);
      this.$message.error(`${msg}`);
      return;
    } else {
      this.$emit("scannedVid", data.vids)
    }
  }

  public scanVid() {
    if(this.formCannotEdit){
        return
    }

    let labelType = this.isBatchCode ? "BATCH_CODE" : "PRODUCT_CODE"
    // let tagType = this.$route.query.scene === Scene.LOGISTIS_SEND ? 'BATCH_CODE' : labelType
    let applyno = this.$route.query.applyno || ""

    let usable = false;
    const pageName = this.$store.state.pageName;
    if (pageName === 'PRODUCT_BIND') {
      usable = true;
    } else if (pageName === 'RECEIVE') {
      usable = false;
    }

    let data = {
      max: this.vidCountLimit,
      bizType: BizType,
      label_type: labelType,
      applyno,
      project_uuid: this.$route.query.project_uuid,
      usable: usable ? 'true': 'false', // 是否可用，赋码追溯传 true，收货管理传 false
    }
    let scanOptions = this.selectedScannerOption
    if(scanOptions == 'qr.customid_range'){
      scanOptions = 'qr.customid_single'
    }
    let dataSourceType = `dataSource.${scanOptions}`

    const requestId = createRequestId();

    callApp("getData",{ type: dataSourceType, data }, "cbGetData",undefined,requestId)
      .then((resData: any) => {
        if (!this.isIos) {
          const windowObj = window as any
          windowObj["web.setVidData" + requestId] = (data: any) => {
            if (this.vidCountLimit - this.scannedVidList.length < 0) {
              this.$toast({
                message: this.$t("app.scan_limit"),
                position: "center",
                iconClass: "icon el-icon-warning",
                duration: "3000"
              });
              return;
            }

            this.scannedVidList = data.vids
            // this.$emit("scannedVid", this.scannedVidList)
            this.getErrorCodeToNext(data)
          };
        } else {
          this.scannedVidList = resData.vids;
          // this.$emit("scannedVid", this.scannedVidList)
          this.getErrorCodeToNext(resData)
        }
      })
  }
}
