





























































import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import callApp from "@/services/sdk"
@Component({
  components: {},
  filters:{}
})
export default class VidSetting extends Vue {

  // props
  @Prop({ default: false})
  public disabled!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  /// data
  private data: Object = {}
  private dialogVisible: boolean = false
  private scannerOptions: Array<string> = [
    // "nfc.single", "qr.single", "qr.multi","qr.customid_single", "qr.customid_multi", "qr.customid_range"
  ]

  private scannerOptionsArray: Array<any> = []
  private selectedScannerOption: string = ''

  @Provide() provideData: Object = {}

  // computed
  private get infos(): Array<any> {
      return Array<any>()
  }

  public getVidSource(callback: any | undefined = ()=>{} ) {
    callApp("getVidSource",{ type: "device.vidSource", data: { max: this.vidCountLimit } },"cbGetVidSource", undefined, undefined)
      .then((data: any) => {
        this.$store.state.vidlistComponents.forEach((instance: any) => {
          // 切换扫描类别时, 关闭扫描的弹窗
          instance.dialogVisible = false;
        })

        this.scannerOptions = data.vidSources;
        // 单扫限制起止码及多扫
        if (this.vidCountLimit <= 1) {
          this.scannerOptions = data.vidSources.filter((item: any) => { return item.indexOf('single') > 0 })
        } else {
          /// 多个时, 限制二维码单扫录入
          this.scannerOptions = data.vidSources.filter((item: any) => {
            return !(item.indexOf('single') > 0 && item.startsWith('qr'))
          })
        }

        this.scannerOptionsArray = this.scannerOptions.reverse().filter(item => {
          if (item.startsWith('nfc')) {
            return true
          }

          // 为控制设置面板(批次信息)需展示3个类目做的筛选, 商品标签不受影响
          return this.dcuKeyPath === 'base.vidlist' ? item.indexOf('customid') > 0 : item
          // return item.indexOf('customid') > 0
        })

        this.scannerOptionsArray = this.scannerOptionsArray.map(item => {
          const normalLabel = item.replace('.', '_');
          return {
            label: this.dcuKeyPath === 'base.vidlist' ? normalLabel : `batch_${normalLabel}`, // 批次信息给每个 label 加上 batch_ 标识， 和商品标签区分开
            value: item
          };
        })
        if(callback){
          callback()
        }
    })
  }

  public get settingDialogClass(): string {
    return "scan-setting"
  }

  public getStoredVidSource() {
    let data = { key: this.dcuKeyPath }
    callApp("getSelectedVidSource",{ type: "storage.getSelectedVidSource", data }, "cbGetSelectedVidSource",undefined,undefined )
      .then((resData: any) => {
        let defaultSetting = this.isBatchCode ? "qr.customid_single" : "qr.customid_range"
        this.selectedScannerOption = resData || defaultSetting
      })
  }

  mounted() {
    this.getVidSource(()=>{
      this.getStoredVidSource()
    })
  }

  /// methods
  public show(): void {
    this.dialogVisible = true
    this.getVidSource(()=>{
      this.getStoredVidSource()
    })
  }

  public confirmScannerOptions() {
    const data = { val: this.selectedScannerOption , key: this.dcuKeyPath }
    callApp( "updateSelectedVidSource",{ type: "storage.updateSelectedVidSource", data }, "cbUpdateSelectedVidSource", undefined, undefined )
      .then( (data: any) => {
      this.dialogVisible = false
      this.$emit("confirmSetting", this.selectedScannerOption);
    })
  }
}
